import {encode as base64_encode} from 'base-64';
import getLocalData from "./getLocalData";

export const fetchAPI = (endpoint,method,body,header) => {

    const requiredLogData = {
      ...body,
      Log: {
        UsersId: getLocalData("user", "id"),
        TenantId: getLocalData("tenant", "id"),
        IsPortal: 1,
      }
    }

    const headers = {
      ...header,
      "Authorization": "Basic " + base64_encode("gestelevapiuser:Luskas#1981")
    }

    return fetch('https://api.gestelev.xyz'+endpoint, {
        method: method,
        headers: headers,
        body: method==='POST'?JSON.stringify(requiredLogData):null
    })
      .then((res) => res.json()).then((responseData) => {
        return responseData;
      })
  
  }