import { useState, useEffect } from 'react';


const PopUpLoading = (props) => {

    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    },[props.open]); // Only fire if props.open changes

    return (
        <div className="popuploading-container-custom" style={{display: props.open ? 'block' : 'none' }}>
        <div className="loading-contents">
          <div>
            <div className="popuploading-container">
              <div className="loading-speeding-wheel medium dark-gray-loading"></div>
              <div className="margin-top margin-bottom">{props.message}</div>
            </div>
          </div>
        </div>
      </div>
    );

}

export default PopUpLoading;