import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const SnackbarAlert = (props) => {

    const [open, setOpen] = useState(props.open);

    const handleClose = (reason) => {
      if (reason !== "clickaway") {
        setOpen(false);
      }
    };

    useEffect(() => {
        setOpen(props.open);
    },[props.open]); // Only fire if props.open changes

    return (
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={handleClose}
          severity={props.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    );

}

export default SnackbarAlert;