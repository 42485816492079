import { React, useState, useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import DocumentTitle from '../../../functions/setDocumentTitle';
import { fetchAPI } from '../../../functions/fetchAPI';
import SnackbarAlert from '../../../components/SnackbarAlert';
import Loading from '../../../components/Loading';
import { Form } from "react-bootstrap";
import DropdownAPIList from '../../../components/DropdownAPIListValidation';
import ContactList from '../../../components/contact/list_Contact';
import AddressList from '../../../components/address/list_Address';
import NoteList from '../../../components/note/list_Note';
import ProposalList from '../../../components/proposal/list_Proposal';
import ContractList from '../../../components/contract/list_Contract';
import BudgetProposalList from '../../../components/budgetproposal/list_budgetProposal';
import ElevatorList from '../../../components/elevator/list_Elevator';
import AddressCreate from "../../../components/address/popup_create";
import ContactCreate from "../../../components/contact/popup_create";
import NoteCreate from "../../../components/note/popup_create";

const CustomersDetails = () => {

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState({
    isLoading: false,
    message: ""
  });
  /**/

  const [form_Data, setFormData] = useState({
    Customers_Id: '',
    Customers_Reference: '',
    Customers_Name: '',
    Customers_TaxNumber: '',
    CustomersStatus: {
      Id: '',
      Name: '',
      Code: '',
    },
  });

  const [modalAddressCreate, setModalAddressCreate] = useState(false);
  const [modalContactCreate, setModalContactCreate] = useState(false);
  const [modalNoteCreate, setModalNoteCreate] = useState(false);

  const [refreshComponents, setRefreshComponents] = useState({
    addressList: '',
    contactList: '',
    noteList: '',
  });

  async function getCustomersDetail(id) {
    setLoading({
      isLoading: true,
      message: "A obter dados ..."
    });
    try {
      const fetchData = await fetchAPI(
        "/api.php/customer/detail?id=" + id,
        "GET"
      );
      const res = await fetchData;
      setFormData(res);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
    setLoading({
      isLoading: false
    });
  }

  async function updateCustomer() {
    setLoading({
      isLoading: true,
      message: "A atualizar o cliente ..."
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/customer/update",
        "POST",
        {
          Customer: {
            Id: form_Data.Customers_Id,
            Name: form_Data.Customers_Name,
            TaxNumber: form_Data.Customers_TaxNumber,
            CustomerStatusId: form_Data.CustomersStatus.Id,
            PaymentMethodId: form_Data.PaymentsMethod_Id?form_Data.PaymentsMethod_Id:'NULL',
            CustomersComesFromId: form_Data.CustomersComesFrom_Id?form_Data.CustomersComesFrom_Id:'NULL',
            CustomerManagerId: form_Data.CustomersManagers_Id?form_Data.CustomersManagers_Id:'NULL',
            RouteId: form_Data.Routes_Id?form_Data.Routes_Id:'NULL',
            DoorCode: form_Data.Customers_DoorCode
          }
        }
      );
      const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
    } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
          type: "error",
        });
    }
    setLoading({
      isLoading: false
    });
    set_Validated(false);
  }

  const location = useLocation();

  useEffect(() => {
    getCustomersDetail(location.state.id);
  }, [location]);

  /* Form validation/submit */
  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateCustomer();
    }
  };

  const chngFn = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...form_Data,
      [id]: value,
    });
  };
  /**/

  return (
    <>
      {DocumentTitle("Detalhes Cliente")}
      <Header />
      <SideNav activeItem={2} />
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <Loading open={loading.isLoading} message={loading.message} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Referência {form_Data.Customers_Reference}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/customer/list">Clientes</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Detalhes do Cliente
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Informação do Cliente</h3>
              </div>

              <Form
                noValidate
                method="post"
                onSubmit={submitFn}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="Customers_Name">
                        Nome (contato)
                      </label>
                      <Form.Control
                        type="text"
                        name="Name"
                        autocomplete="off"
                        className="form-control"
                        id="Customers_Name"
                        value={form_Data.Customers_Name}
                        onChange={chngFn}
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="Customers_TaxNumber">Nº Contribuinte</label>
                      <Form.Control
                        type="text"
                        name="TaxNumber"
                        className="form-control"
                        id="Customers_TaxNumber"
                        value={form_Data.Customers_TaxNumber}
                        onChange={chngFn}
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="CustomersStatus" className="required">
                        Estado
                      </label>
                      <DropdownAPIList
                        name="StatusId"
                        id="CustomersStatus"
                        endpoint="/api.php/customer/listStatus"
                        invalidMessage="O Estado é obrigatório!"
                        defaultValue={form_Data.CustomersStatus}
                        required={true}
                        label="Escolher Estado"
                        isInvalid={!form_Data.CustomersStatus.Id && validated}
                        onChange={(value) =>
                          chngFn({
                            target: { id: "CustomersStatus", value: value },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                  >
                    Gravar
                  </button>
                </div>
              </Form>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-secondary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-four-home-tab"
                          data-toggle="pill"
                          href="#tab_1"
                          role="tab"
                          aria-controls="tab_1"
                          aria-selected="true"
                        >
                          Detalhe do Cliente
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-profile-tab"
                          data-toggle="pill"
                          href="#tab_2"
                          role="tab"
                          aria-controls="tab_2"
                          aria-selected="false"
                        >
                          Informação Contratual
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-messages-tab"
                          data-toggle="pill"
                          href="#tab_3"
                          role="tab"
                          aria-controls="tab_3"
                          aria-selected="false"
                        >
                          Elevadores
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-settings-tab"
                          data-toggle="pill"
                          href="#tab_4"
                          role="tab"
                          aria-controls="tab_4"
                          aria-selected="false"
                        >
                          Manutenções
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_1">
                        <div className="card card-secondary mt-3">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Contactos</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalContactCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <ContactCreate
                              customerId={location.state.id}
                              show={modalContactCreate}
                              onHide={() => setModalContactCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ contactList: new Date() })
                                setModalContactCreate(false)
                                }
                              }
                            />
                            <ContactList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              refreshControl={refreshComponents.contactList}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Endereços</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalAddressCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <AddressCreate
                              customerId={location.state.id}
                              show={modalAddressCreate}
                              onHide={() => setModalAddressCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ addressList: new Date() })
                                setModalAddressCreate(false)
                                }
                              }
                            />
                            <AddressList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              refreshControl={refreshComponents.addressList}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Notas</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalNoteCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <NoteCreate
                              customerId={location.state.id}
                              show={modalNoteCreate}
                              onHide={() => setModalNoteCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ noteList: new Date() })
                                setModalNoteCreate(false)
                                }
                              }
                            />
                            <NoteList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              refreshControl={refreshComponents.noteList}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_2">
                        <div className="card card-secondary mt-3">
                          <div className="card-header">
                            <h3 className="card-title">Propostas</h3>
                          </div>
                          <div className="card-body">
                            <ProposalList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              columnVisibility={{
                                CustomersAddress_Street: false,
                                Customers_Name: false,
                              }}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title">Contratos</h3>
                          </div>
                          <div className="card-body">
                            <ContractList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              columnVisibility={{
                                CustomersAddress_Street: false,
                                Customers_Name: false,
                              }}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title">Orçamentos</h3>
                          </div>
                          <div className="card-body">
                            <BudgetProposalList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              columnVisibility={{
                                CustomersAddress_Street: false,
                                Customers_Name: false,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_3">
                        <div className="mt-3">
                          <ElevatorList
                            customerId={location.state.id}
                            blankStateOnlyText={true}
                            columnVisibility={{
                              CustomersAddress_Street: false,
                              Customers_Name: false,
                            }}
                          />
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_4">
                        Em desenvolvimento ...
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      
      <Footer />
    </>
  );
};

export default CustomersDetails;
