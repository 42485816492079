
import { React, useState } from 'react';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import PopUpLoading from '../PopUpLoading';
import { Modal, Form } from "react-bootstrap";
import DropdownAPIList from '../DropdownAPIListValidation';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Swal from 'sweetalert2';
import validateContact from '../../functions/validateCustomerContact';


const ContactCreate = (props) => {

    const [loading, setLoading] = useState({
        isLoading: false,
        message: "",
    });

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        alert: "",
      });

    const [form_Data, setFormData] = useState({
        CustomersContacts_IsMain: '1',
        CustomersContacts_Value: '',
        ContactTypes: {
          Id: '',
          Name: '',
          Code: '',
        },
    });

    async function createContact() {
        setLoading({
          isLoading: true
        });
        setAlert({ open: false, type: alert.type, message: alert.message });
        try {
          const fetchData = await fetchAPI(
            "/api.php/contact/create",
            "POST",
            {
              CustomersContact: {
                CustomerId: props.customerId,
                IsMain: form_Data.CustomersContacts_IsMain,
                ContactTypeId: form_Data.ContactTypes.Id,
                Value: form_Data.CustomersContacts_Value,
              }
            }
          );
          const res = await fetchData;
            setAlert({
              open: true,
              message: res.message,
              type: res.status,
            });
            setFormData({
              CustomersContacts_IsMain: '1',
              CustomersContacts_Value: '',
              ContactTypes: {
                Id: '',
                Name: '',
                Code: '',
              },
            });
            setLoading({
              isLoading: false
            });
            props.onSave();
        } catch (err) {
            setAlert({
              open: true,
              message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
              type: "error",
            });
            setLoading({
              isLoading: false
            });
        }
        set_Validated(false);
    }


    async function getCustomerContactByType() {
      setLoading({
        isLoading: true
      });
      setAlert({ open: false, type: alert.type, message: alert.message });
      try {
        const fetchData = await fetchAPI(
          "/api.php/contact/listByType?customerId="+props.customerId+"&contactTypeId="+form_Data.ContactTypes.Id+"&isMain=1",
          "GET"
        );
        const res = await fetchData;
          if(res.length>0) {
            Swal.fire({
              title: "Preferência do contacto",
              text: "O contacto "+res[0].CustomersContacts_Value+" já está definido como Principal. Se escolher este como principal o atual passará a Secundário. Pretende continuar? ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Sim, continuar"
            }).then((result) => {
              if (result.isConfirmed) {
                createContact();
              }
            });
            setLoading({
              isLoading: false
            });
          } else {
            createContact();
          }
      } catch (err) {
          setAlert({
            open: true,
            message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
            type: "error",
          });
          setLoading({
            isLoading: false
          });
      }
      set_Validated(false);
    }


    const [validated, set_Validated] = useState(false);
    const [contactValidation, setContactValidation] = useState({
        isInvalid: true,
        message: ''
    });

    const submitFn = (event) => {
        
        const form = event.currentTarget;

        set_Validated(true);

        if (form.checkValidity() === false || contactValidation.isInvalid) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          event.preventDefault();
          if(form_Data.CustomersContacts_IsMain==='1') {
            getCustomerContactByType();
          } else {
            createContact();
          }
        }
    };

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        if(name==='CustomersContacts_Value' && form_Data.ContactTypes.Id) {
          setContactValidation(validateContact(value,form_Data.ContactTypes.Code));
        }

        if(name==='ContactTypes' && form_Data.CustomersContacts_Value) {
          setContactValidation(validateContact(form_Data.CustomersContacts_Value,value.Code));
        }

        setFormData({
          ...form_Data,
          [name]: value,
        });

    };

    
    return (
      <>
        <SnackbarAlert
          open={alert.open}
          message={alert.message}
          type={alert.type}
        />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.show}
          onHide={() => {
            props.onHide();
          }}
          backdrop="static"
        >
          <PopUpLoading open={loading.isLoading} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Adicionar Contacto
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid pb-4">
              <Form
                noValidate
                method="post"
                onSubmit={submitFn}
                id="contactForm"
              >
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="ContactTypes_Id" className="required">
                      Tipo de Contacto
                    </label>
                    <DropdownAPIList
                      id="ContactTypes_Id"
                      name="ContactTypes"
                      endpoint="/api.php/contact/listTypes"
                      invalidMessage="O Tipo de Contacto é obrigatório!"
                      required={true}
                      isInvalid={
                          !form_Data.ContactTypes.Id &&
                          validated
                        }
                      label="Escolher Tipo"
                      onChange={(value) =>
                        handleOnChange({
                          target: { name: "ContactTypes", value: value },
                        })
                      }
                    />
                  </div>
                  <div className="col-6"></div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <label>
                      Preferência
                    </label>
                    <RadioGroup
                      row
                      name="CustomersContacts_IsMain"
                      value={form_Data.CustomersContacts_IsMain}
                      onChange={handleOnChange}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="Principal" />
                      <FormControlLabel value="0" control={<Radio />} label="Secundário" />
                    </RadioGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="Value">
                      Contacto
                    </label>
                    <Form.Control
                      type="text"
                      name="CustomersContacts_Value"
                      className="form-control"
                      id="Value"
                      value={form_Data.CustomersContacts_Value}
                      required={true}
                      onChange={handleOnChange}
                      isInvalid={
                          (!form_Data.CustomersContacts_Value || contactValidation.isInvalid) &&
                          validated
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                      {!form_Data.CustomersContacts_Value?'O Contacto é obrigatório!':contactValidation.message}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary mr-auto"
              type="submit"
              form="contactForm"
            >
              Adicionar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
}


export default ContactCreate