import { React, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../../components/SnackbarAlert';


const Test = () => {

  const [data, setData] = useState([]);

   /* Alert */
   const [alert, setAlert] = useState({
    open: false,
    message: '',
    alert: ''
  });
  /**/


  useEffect(() => {
    const getCustomersList = async () => {
      try {
        const fetchData = await fetchAPI(
          "/api.php/customer/list?tenant=1",
          "GET"
        );
        const res = await fetchData;
        setData(res);
      } catch (err) {
        setAlert({ open: true, message: 'Ocorreu um erro a tentar obter os dados. Tente novamente.', type: 'error' });
      }
    };
    getCustomersList()
  }, []);


  const columns = [ 
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Nome", width: 130 },
    { field: "nif", headerName: "NIF", width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      type: 'actions',
      width: 330,
      renderCell: (params) => (
        <>
          <button className="btn btn-primary btn-sm" onClick={() => alert(params.row.nif)}>
                        <i className="fas fa-folder"></i>
                        View
                      </button>
        </>

      ),
    },
  ];

const rows = data;

  return (
    <div style={{ height: '100%', width: '100%' }}>
    <SnackbarAlert open={alert.open} message={alert.message} type={alert.type} />
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 50 },
        },
      }}
      pageSizeOptions={[20, 50, 100]}

    />
    
  </div>
  );

};

export default Test;