import { React, useState } from 'react';
import DocumentTitle from '../../../functions/setDocumentTitle';
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import SnackbarAlert from '../../../components/SnackbarAlert';
import Loading from '../../../components/Loading';
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DropdownAPIList from '../../../components/DropdownAPIListValidation';
import getLocalData from "../../../functions/getLocalData";
import { fetchAPI } from '../../../functions/fetchAPI';
import validateContact from '../../../functions/validateCustomerContact';

const CustomersCreate = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState({
    isLoading: false,
    message: "",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });

  const [form_Data, setFormData] = useState({
    Customers_Name: "",
    Customers_TaxNumber: "",
    CustomersAddress_Street: "",
    CustomersAddress_NumberFloor: "",
    CustomersAddress_ZipCode: "",
    CustomersAddress_Local: "",
    CustomersAddress_City: "",
    ContactsPhone_Value: "",
    ContactsCell_Value: "",
    ContactsEmail_Value: "",
    PaymentsMethod: {
      Id: '',
      Name: '',
      Code: '',
    },
    CustomersManagers: {
      Id: '',
      Name: '',
      Code: '',
    },
    Routes: {
      Id: '',
      Name: '',
      Code: '',
    },
    CustomersComesFrom: {
      Id: '',
      Name: '',
      Code: '',
    },
    Customers_DoorCode: "",
  });

  async function createCustomer() {
    setLoading({
      isLoading: true,
      message: "A adicionar o cliente ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/customer/create",
        "POST",
        {
          Customer: {
            Name: form_Data.Customers_Name,
            TaxNumber: form_Data.Customers_TaxNumber,
            PaymentMethodId: form_Data.PaymentsMethod.Id?form_Data.PaymentsMethod.Id:'NULL',
            CustomersComesFromId: form_Data.CustomersComesFrom.Id?form_Data.CustomersComesFrom.Id:'NULL',
            CustomerManagerId: form_Data.CustomersManagers.Id?form_Data.CustomersManagers.Id:'NULL',
            RouteId: form_Data.Routes.Id?form_Data.Routes.Id:'NULL',
            DoorCode: form_Data.Customers_DoorCode
          },
          CustomersAddress: {
            Street: form_Data.CustomersAddress_Street,
            City: form_Data.CustomersAddress_City,
            ZipCode: form_Data.CustomersAddress_ZipCode,
            Local: form_Data.CustomersAddress_Local,
            NumberFloor: form_Data.CustomersAddress_NumberFloor,
          },
          CustomersContactsCell: {
            Value: form_Data.ContactsCell_Value
          },
          CustomersContactsEmail: {
            Value: form_Data.ContactsEmail_Value
          },
          CustomersContactsPhone: {
            Value: form_Data.ContactsPhone_Value
          }
        }
      );
      const res = await fetchData;
      if (res.status === "success") {
        navigate("/customer/details", { state: { id: res.Id } });
      } else {
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a adicionar os dados. Tente novamente.",
        type: "error",
      });
    }
    setLoading({
      isLoading: false,
    });
    set_Validated(false);
  }

  async function getTaxNumber(taxNumber) {
    setLoading({
      isLoading: true,
      message: "A verificar NIF ...",
    });
    try {
      const fetchData = await fetchAPI(
        "/api.php/externalservices/taxnumber?taxnumber=" + taxNumber,
        "GET"
      );
      const res = await fetchData;
      if (res.status === "error") {
        setAlert({
          open: true,
          message: "O Nº Contribuinte " + taxNumber + " não parece ser válido.",
          type: "warning",
        });
      } else {
        setFormData({
          ...form_Data,
          Customers_Name:
            res.Title !== "" ? res.Title : form_Data.Customers_Name,
          Customers_TaxNumber: taxNumber,
          CustomersAddress_Street:
            res.Address !== ""
              ? res.Address
              : form_Data.CustomersAddress_Street,
          CustomersAddress_Local:
            res.City !== "" ? res.City : form_Data.CustomersAddress_Local,
          CustomersAddress_State:
            res.Region !== "" ? res.Region : form_Data.CustomersAddress_State,
          CustomersAddress_City:
            res.County !== "" ? res.County : form_Data.CustomersAddress_City,
          CustomersAddress_ZipCode:
            res.ZipCode1 !== "" || res.ZipCode2 !== ""
              ? res.ZipCode1 + "-" + res.ZipCode2
              : form_Data.CustomersAddress_ZipCode,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu a tentar obter a informação.",
        type: "error",
      });
    }
    setLoading({
      isLoading: false,
    });
  }

  /* Form validation/submit */
  const [validated, set_Validated] = useState(false);
  const [contactCELL, setContactCELL] = useState({
      isInvalid: false,
      message: ''
  });
  const [contactPHONE, setContactPHONE] = useState({
      isInvalid: false,
      message: ''
  });
  const [contactEMAIL, setContactEMAIL] = useState({
      isInvalid: false,
      message: ''
  });

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (!form.checkValidity() || 
      contactCELL.isInvalid || 
      contactEMAIL.isInvalid || 
      contactPHONE.isInvalid ||
      !form_Data.Customers_Name ||
      !form_Data.CustomersAddress_Street ||
      !form_Data.CustomersAddress_ZipCode ||
      !form_Data.CustomersAddress_Local ||
      !form_Data.CustomersAddress_City ||
      !form_Data.ContactsEmail_Value ||
      (!form_Data.ContactsPhone_Value && !form_Data.ContactsCell_Value)) {
        event.preventDefault();
        event.stopPropagation();
    } else {
      event.preventDefault();
      createCustomer();
    }
  };

  const handleOnChange = (event) => {
    const { id, value } = event.target;

    if (id === "Customers_TaxNumber" && value.length === 9) {
      getTaxNumber(value);
    }

    if(id==='ContactsCell_Value') {
      setContactCELL(validateContact(value,'CELL'));
    }

    if(id==='ContactsPhone_Value') {
      setContactPHONE(validateContact(value,'TEL'));
    }

    if(id==='ContactsEmail_Value') {
      setContactEMAIL(validateContact(value,'EMAIL'));
    }

    setFormData({
      ...form_Data,
      [id]: value,
    });
  };
  /**/

  console.log(form_Data);

  return (
    <>
      {DocumentTitle("Criar Cliente")}
      <Header />
      <SideNav activeItem={2} />
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <Loading open={loading.isLoading} message={loading.message} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Criar Cliente</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/customer/list">Clientes</Link>
                  </li>
                  <li className="breadcrumb-item active">Criar Cliente</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid pb-4">
            <Form noValidate method="post" onSubmit={submitFn}>
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Informação do Cliente</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="Customers_TaxNumber">
                        Nº Contribuinte
                      </label>
                      <Form.Control
                        type="text"
                        name="TaxNumber"
                        className="form-control"
                        id="Customers_TaxNumber"
                        value={form_Data.Customers_TaxNumber}
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className="col-9">
                      <label htmlFor="Customers_Name" className="required">
                        Nome
                      </label>
                      <Form.Control
                        type="text"
                        name="Name"
                        autoComplete="off"
                        className="form-control"
                        id="Customers_Name"
                        value={form_Data.Customers_Name}
                        isInvalid={form_Data.Customers_Name === "" && validated}
                        onChange={handleOnChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        O Nome é obrigatório!
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <hr className="mt-4 mb-4" />
                  <div className="row">
                    <div className="col-8">
                      <label
                        htmlFor="CustomersAddress_Street"
                        className="required"
                      >
                        Endereço
                      </label>
                      <Form.Control
                        type="text"
                        name="Street"
                        className="form-control"
                        id="CustomersAddress_Street"
                        value={form_Data.CustomersAddress_Street}
                        isInvalid={
                          !form_Data.CustomersAddress_Street && validated
                        }
                        onChange={handleOnChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        O Endereço é obrigatório!
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-4">
                      <label htmlFor="CustomersAddress_NumberFloor">
                        Porta/Fração
                      </label>
                      <Form.Control
                        type="text"
                        name="NumberFloor"
                        className="form-control"
                        id="CustomersAddress_NumberFloor"
                        value={form_Data.CustomersAddress_NumberFloor}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4">
                      <label
                        htmlFor="CustomersAddress_ZipCode"
                        className="required"
                      >
                        Código Postal
                      </label>
                      <Form.Control
                        type="text"
                        name="ZipCode"
                        className="form-control"
                        id="CustomersAddress_ZipCode"
                        value={form_Data.CustomersAddress_ZipCode}
                        onChange={handleOnChange}
                        isInvalid={
                          !form_Data.CustomersAddress_ZipCode && validated
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        O Código Postal é obrigatório!
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-4">
                      <label
                        htmlFor="CustomersAddress_Local"
                        className="required"
                      >
                        Localidade
                      </label>
                      <Form.Control
                        type="text"
                        name="Local"
                        className="form-control"
                        id="CustomersAddress_Local"
                        value={form_Data.CustomersAddress_Local}
                        onChange={handleOnChange}
                        isInvalid={
                          !form_Data.CustomersAddress_Local && validated
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        A Localidade é obrigatória!
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-4">
                      <label
                        htmlFor="CustomersAddress_City"
                        className="required"
                      >
                        Concelho
                      </label>
                      <Form.Control
                        type="text"
                        name="City"
                        className="form-control"
                        id="CustomersAddress_City"
                        value={form_Data.CustomersAddress_City}
                        onChange={handleOnChange}
                        isInvalid={
                          !form_Data.CustomersAddress_City && validated
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        O Concelho é obrigatório!
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <hr className="mt-4 mb-4" />
                  <div className="row mt-4">
                    <div className="col-4">
                      <label htmlFor="ContactsPhone_Value" className="required">
                        Telefone
                        <span className="required" />
                      </label>
                      <Form.Control
                        type="number"
                        name="Phone"
                        autoComplete="off"
                        className="form-control"
                        id="ContactsPhone_Value"
                        value={form_Data.ContactsPhone_Value}
                        isInvalid={
                          ((!form_Data.ContactsPhone_Value &&
                          !form_Data.ContactsCell_Value) || contactPHONE.isInvalid) &&
                          validated
                        }
                        onChange={handleOnChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {!form_Data.ContactsPhone_Value?'O Telefone ou o Telemóvel é obrigatório!':contactPHONE.message}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-4">
                      <label htmlFor="ContactsCell_Value" className="required">
                        Telemóvel
                        <span className="required" />
                      </label>
                      <Form.Control
                        type="number"
                        name="Cell"
                        className="form-control"
                        id="ContactsCell_Value"
                        value={form_Data.ContactsCell_Value}
                        isInvalid={
                          ((!form_Data.ContactsPhone_Value &&
                          !form_Data.ContactsCell_Value) || contactCELL.isInvalid) &&
                          validated
                        }
                        onChange={handleOnChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {!form_Data.ContactsCell_Value?'O Telemóvel ou o Telefone é obrigatório!':contactCELL.message}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-4">
                      <label htmlFor="ContactsEmail_Value" className="required">
                        E-mail
                      </label>
                      <Form.Control
                        type="text"
                        name="Email"
                        autoComplete="off"
                        className="form-control"
                        id="ContactsEmail_Value"
                        value={form_Data.ContactsEmail_Value}
                        onChange={handleOnChange}
                        isInvalid={
                          (!form_Data.ContactsEmail_Value || contactEMAIL.isInvalid) && 
                          validated
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {!form_Data.ContactsEmail_Value?'O E-mail é obrigatório!':contactEMAIL.message}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-secondary mt-4">
                <div className="card-header">
                  <h3 className="card-title">Outras Informações</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="PaymentsMethod">
                        Método de Pagamento
                      </label>
                      <DropdownAPIList
                        name="PaymentMethodId"
                        id="PaymentsMethod"
                        endpoint={
                          "/api.php/system/paymentmethod/list?isEnabled=1&tenantId=" +
                          getLocalData("tenant", "id")
                        }
                        defaultValue={form_Data.PaymentsMethod}
                        required={false}
                        label="Escolher Método"
                        onChange={(value) =>
                          handleOnChange({
                            target: { id: "PaymentsMethod", value: value },
                          })
                        }
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="CustomersManagers">Gestora</label>
                      <DropdownAPIList
                        name="CustomersManagersId"
                        id="CustomersManagers"
                        endpoint={
                          "/api.php/system/customermanager/list?isEnabled=1&tenantId=" +
                          getLocalData("tenant", "id")
                        }
                        defaultValue={form_Data.CustomersManagers}
                        required={false}
                        label="Escolher Gestora"
                        onChange={(value) =>
                          handleOnChange({
                            target: {
                              id: "CustomersManagers",
                              value: value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="Routes">Rota</label>
                      <DropdownAPIList
                        name="RoutesId"
                        id="Routes"
                        endpoint={
                          "/api.php/system/route/list?isEnabled=1&tenantId=" +
                          getLocalData("tenant", "id")
                        }
                        defaultValue={form_Data.Routes}
                        required={false}
                        label="Escolher Rota"
                        onChange={(value) =>
                          handleOnChange({
                            target: { id: "Routes", value: value },
                          })
                        }
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="CustomersComesFrom">
                        Proveniência do Cliente
                      </label>
                      <DropdownAPIList
                        name="CustomersComesFromId"
                        id="CustomersComesFrom"
                        endpoint={
                          "/api.php/system/comesfrom/list?isEnabled=1&tenantId=" +
                          getLocalData("tenant", "id")
                        }
                        defaultValue={form_Data.CustomersComesFrom}
                        required={false}
                        label="Escolher Proveniência"
                        onChange={(value) =>
                          handleOnChange({
                            target: {
                              id: "CustomersComesFrom",
                              value: value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-3">
                      <label htmlFor="Customers_DoorCode">
                        Código da Porta
                      </label>
                      <Form.Control
                        type="text"
                        name="DoorCode"
                        className="form-control"
                        id="Customers_DoorCode"
                        value={form_Data.Customers_DoorCode}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <button
                    name="SubmitButton"
                    type="submit"
                    className="btn btn-secondary"
                  >
                    Gravar
                  </button>
                  <button
                    name="SubmitButton"
                    type="button"
                    className="btn btn-outline-secondary ml-3"
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <Footer />
    </>
  );
};

export default CustomersCreate;