import Chip from '@mui/material/Chip';

const ContractStatusTag = (props) => {

      if(props.StatusCode === "ACTIVE") {
         return (
          <Chip label={props.StatusName}  color="success" />
         );
      } else if(props.StatusCode === "SENTPENDING") {
        return (
          <Chip label={props.StatusName}  color="primary" />
         );
      } else if(props.StatusCode === "CANCEL") {
        return (
          <Chip label={props.StatusName}  color="default" />
         );
      } else if(props.StatusCode === "EXPIRED") {
        return (
          <Chip label={props.StatusName}  color="error" />
         );
      }

}
    
    export default ContractStatusTag