import { React, useState, useEffect} from 'react';
import MuiDataGrid from '../DataGrid';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import getLocalData from "../../functions/getLocalData";
import ProposalStatusTag from '../proposal/proposalStatusTag';
import ProposalExpiration from '../proposal/proposalExpiration';

const BudgetProposalList = (props) => {
  const [data, setData] = useState([]);

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState(false);
  /**/

  async function getBudgetProposalList(customerId) {

    const CustomerId = customerId?customerId:0;

    setLoading(true);
    try {
      const fetchData = await fetchAPI(
        "/api.php/budgetproposal/list?customerId="+CustomerId+"&tenantId="+getLocalData("user", "tenant"),
        "GET"
      );
      const res = await fetchData;
      setData(res);
      setLoading(false);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getBudgetProposalList(props.customerId);
  }, [props.customerId]);

  const columns = [
    {
      field: "Reference",
      headerName: "Referência",
      flex: 1,
    },
    {
      field: "Customers_Name",
      headerName: "Cliente",
      flex: 1,
      renderCell: (params) => (
        params.row.Customers_Reference + ' - ' + params.row.Customers_Name
      ),
    },
    {
      field: "CustomersAddress_Street",
      headerName: "Endereço",
      flex: 1,
    },
    { 
      field: "BudgetProposal_Value", 
      headerName: "Valor", 
      flex: 1,
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("pt-PT", {style:"currency", currency:"EUR"});
      },
    },
    {
      field: "BudgetProposal_SentDate",
      headerAlign: "center",
      align: "center",
      headerName: "Data de Expiração",
      flex: 1,
      renderCell: (params) => (
        <ProposalExpiration
          SentDate={params.row.BudgetProposal_SentDate}
          StatusCode={params.row.BudgetProposalStatus_Code}
          ExpirationDays={params.row.BudgetProposal_BudgetProposalExpirationDays}
          ConfigExpirationDays={params.row.ConfigAlerts_ProposalExpirationDays}
         />
      ),
    },
    {
      field: "BudgetProposalStatus_Code",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      flex: 1,
      renderCell: (params) => (
        <ProposalStatusTag 
          StatusCode={params.row.BudgetProposalStatus_Code}
          StatusName={params.row.BudgetProposalStatus_Name}
        />
      ),
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => console.log(params.row.BudgetProposal_Id)}
          >
            <i className="fas fa-folder pr-2"></i>
            Consultar
          </button>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={props.pageSize?props.pageSize:10}
        loading={loading}
        rowId={(params) => params.BudgetProposal_Id}
        blankStateOnlyText={props.blankStateOnlyText}
        sortAsc={true}
        sortField="Reference"
        columnVisibility={{
          ...props.columnVisibility
        }}
      />
    </>
  );
}

export default BudgetProposalList
