

const validateContact = (value,contactCode) => {

    if(contactCode==='EMAIL') {

       const isValid = String(value)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        
        return ({
            isInvalid: !isValid,
            message: isValid?'':'O Email não é válido!'
        });

    }


    if(contactCode==='CELL' || contactCode==='FAX' || contactCode==='TEL') {

        if(!value.match(/^[0-9]+$/)) {
            return ({
                isInvalid: true,
                message: 'O Contacto apenas pode ter dígitos!'
            });
        }

        if(value.length!==9) {
            return ({
                isInvalid: true,
                message: 'O Contacto tem de ter 9 dígitos!'
            });
        }

        if(contactCode!=='CELL'||
            value.substring(0,2)==='91'||
            value.substring(0,2)==='92'||
            value.substring(0,2)==='93'||
            value.substring(0,2)==='96') {
            return ({
                isInvalid: false,
                message: ''
            });
        } else {
            return ({
                isInvalid: true,
                message: 'O indicativo não corresponde a nenhuma operadora nacional!'
            });
        }
        
    }
      
};

export default validateContact;
