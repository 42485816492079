import React from 'react';
import { fetchAPI } from '../functions/fetchAPI';
import { Form } from "react-bootstrap";

export const DropdownAPI = (props) => (
  <>
    <Form.Select
      type="select"
      as="select"
      className={props.isInvalid?"form-control is-invalid":"form-control"}
      name={props.name}
      onChange={props.onChange}
      value={JSON.stringify(props.defaultValue)}
      id={props.id}
      required={props.required}
    >
      <option value=''>{props.label}</option>
      {props.options.map((item, index) => (
        <option key={index} value={JSON.stringify(item)}>
          {item.Name}
        </option>
      ))}
    </Form.Select>

    {props.inputIcon?
      <div className="input-group-append">
        <div className="input-group-text">
          <span className={props.inputIcon} />
        </div>
      </div>
      :""
    }
    
    <Form.Control.Feedback type="invalid">
      {props.invalidMessage}
    </Form.Control.Feedback>
  </>
);

export default class DropdownAPIList extends React.Component {
    constructor(props) {
    super(props)
    const name = this.props.name;
    const endpoint = this.props.endpoint;

    this.state = {
        name: name,
        endpoint: endpoint,
        collection: [],
        value: '',
    }
  }
  componentDidMount(props) {

    const endpoint = this.props.endpoint;

    fetchAPI(endpoint,'GET')
    .then((e) => this.setState({ collection: e }));
  }
  onChange = (event) => {
    this.setState({ value: event.target.value })
    this.props.onChange(event.target.value?JSON.parse(event.target.value):event.target.value);
  }

  render() {
    return (
        <DropdownAPI
          name={this.state.name}
          endpoint={this.state.endpoint}
          options={this.state.collection}
          onChange={this.onChange}
          invalidMessage={this.props.invalidMessage}
          inputIcon={this.props.inputIcon}
          defaultValue={this.props.defaultValue}
          id={this.props.id}
          required={this.props.required}
          label={this.props.label}
          isInvalid={this.props.isInvalid}
        />
    )
  }
}