import { React } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import BlankState from './BlankState';


const MuiDataGrid = (props) => {

    const noRowsContainer = () => ( 
        <BlankState type="norows" loading={props.loading} onlyText={props.blankStateOnlyText} />
    );

    const noResultsContainer = () => (
        <BlankState type="noresults" onlyText={props.blankStateOnlyText} />
    );

   return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={props.blankStateOnlyText?{'--DataGrid-overlayHeight': '90px'}:{'--DataGrid-overlayHeight': '320px'}}
            rows={props.rows} 
            columns={props.columns}
            getRowId={props.rowId}
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
                ? "Mui-even"
                : "Mui-odd"
            }
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: props.pageSize },
                },
                sorting: {
                    sortModel: [{ field: props.sortField, sort: props.sortAsc ? 'asc' : 'desc' }],
                },
                columns: {
                    columnVisibilityModel: {
                        ...props.columnVisibility
                    }
                },
            }}
            pageSizeOptions={[props.pageSize]}
            localeText={{
                columnMenuShowColumns: 'Mostrar colunas',
                columnMenuManageColumns: 'Gerir colunas',
                columnMenuFilter: 'Filtro',
                columnMenuHideColumn: 'Esconder coluna',
                columnMenuUnsort: 'Unsort',
                columnMenuSortAsc: 'Ordenar por ASC',
                columnMenuSortDesc: 'Ordenar por DESC',
            }}
            slots={{
                noRowsOverlay: noRowsContainer,
                noResultsOverlay: noResultsContainer
            }}
        />   
    )


      
};

export default MuiDataGrid;
