
import { React, useState } from 'react';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import PopUpLoading from '../PopUpLoading';
import { Modal, Form } from "react-bootstrap";
import DropdownAPIList from '../DropdownAPIListValidation';
import Swal from 'sweetalert2';


const AddressCreate = (props) => {

    const [loading, setLoading] = useState({
        isLoading: false,
        message: "",
    });

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        alert: "",
      });

    const [form_Data, setFormData] = useState({
        CustomersAddress_Street: '',
        CustomersAddress_NumberFloor: '',
        CustomersAddress_ZipCode: '',
        CustomersAddress_Local: '',
        CustomersAddress_City: '',
        AddressTypes: {
          Id: '',
          Name: '',
          Code: '',
        },
    });

    async function createAddress() {
        setLoading({
          isLoading: true,
          message: "A adicionar o endereço ..."
        });
        setAlert({ open: false, type: alert.type, message: alert.message });
        try {
          const fetchData = await fetchAPI(
            "/api.php/address/create",
            "POST",
            {
              CustomersAddress: {
                CustomerId: props.customerId,
                AddressTypeId: form_Data.AddressTypes.Id,
                Street: form_Data.CustomersAddress_Street,
                NumberFloor: form_Data.CustomersAddress_NumberFloor,
                ZipCode: form_Data.CustomersAddress_ZipCode,
                Local: form_Data.CustomersAddress_Local,
                City: form_Data.CustomersAddress_City
              }
            }
          );
          const res = await fetchData;
            setAlert({
              open: true,
              message: res.message,
              type: res.status,
            });
            setFormData({
              CustomersAddress_Street: '',
              CustomersAddress_NumberFloor: '',
              CustomersAddress_ZipCode: '',
              CustomersAddress_Local: '',
              CustomersAddress_City: '',
              AddressTypes: {
                Id: '',
                Name: '',
                Code: '',
              },
            });
            setLoading({
              isLoading: false
            });
            props.onSave();
        } catch (err) {
            setAlert({
              open: true,
              message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
              type: "error",
            });
            setLoading({
              isLoading: false
            });
        }
        set_Validated(false);
      }

      async function getZipCode(zipCode) {
        setLoading({
          isLoading: true,
          message: "A verificar Código Postal ...",
        });
        try {
          const fetchData = await fetchAPI(
            "/api.php/externalservices/zipcode?zipcode=" + zipCode,
            "GET"
          );
          const res = await fetchData;
          if (res.status === "error") {
            setAlert({
              open: true,
              message: res.message,
              type: "warning",
            });
          } else {
            setFormData({
              ...form_Data,
              CustomersAddress_Street: res.Rua,
              CustomersAddress_Local: res.Localidade,
              CustomersAddress_State: res.Distrito,
              CustomersAddress_City: res.Concelho,
              CustomersAddress_ZipCode: zipCode,
            });
          }
        } catch (err) {
          setAlert({
            open: true,
            message: "Ocorreu a tentar obter a informação.",
            type: "error",
          });
        }
        setLoading({
          isLoading: false,
        });
      }

      async function getCustomerAddressByTypeCode() {
        setLoading({
          isLoading: true
        });
        setAlert({ open: false, type: alert.type, message: alert.message });
        try {
          const fetchData = await fetchAPI(
            "/api.php/address/listByTypeCode?customerId="+props.customerId+"&addressTypeCode=MAIN",
            "GET"
          );
          const res = await fetchData;
            if(res.length>0) {
              Swal.fire({
                title: "Preferência do contacto",
                text: "O endereço "+res[0].CustomersAddress_Street+" já está definido como Principal. Se escolher este como principal o atual passará a Outro. Pretende continuar? ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim, continuar"
              }).then((result) => {
                if (result.isConfirmed) {
                  createAddress();
                }
              });
              setLoading({
                isLoading: false
              });
            } else {
              createAddress();
            }
        } catch (err) {
            setAlert({
              open: true,
              message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
              type: "error",
            });
            setLoading({
              isLoading: false
            });
        }
        set_Validated(false);
      }

    const [validated, set_Validated] = useState(false);

    const submitFn = (event) => {
        
        const form = event.currentTarget;

        set_Validated(true);

        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        } else {
          event.preventDefault();
          if(form_Data.AddressTypes.Code==='MAIN') {
            getCustomerAddressByTypeCode();
          } else {
            createAddress();
          }
        }
    };

    const chngFn = (event) => {
        const { id, value } = event.target;

        setFormData({
          ...form_Data,
          [id]: value,
        });

        if (id === "CustomersAddress_ZipCode" && value.length === 8) {
          getZipCode(value);
        }

    };

    return (
      <>
        <SnackbarAlert
          open={alert.open}
          message={alert.message}
          type={alert.type}
        />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.show}
          onHide={() => {
            props.onHide();
          }}
          backdrop="static"
        >
          <PopUpLoading open={loading.isLoading} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Adicionar Endereço
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid pb-4">
              <Form
                noValidate
                method="post"
                onSubmit={submitFn}
                id="addressForm"
              >
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="AddressTypes_Id" className="required">
                      Tipo de Endereço
                    </label>
                    <DropdownAPIList
                      name="TypeId"
                      id="AddressTypes"
                      endpoint="/api.php/address/listTypes"
                      invalidMessage="O Tipo de Endereço é obrigatório!"
                      defaultValue={form_Data.AddressTypes_Id}
                      required={true}
                      isInvalid={
                          !form_Data.AddressTypes.Id &&
                          validated
                        }
                      label="Escolher Tipo"
                      onChange={(value) =>
                        chngFn({
                          target: { id: "AddressTypes", value: value },
                        })
                      }
                    />
                  </div>
                  <div className="col-6"></div>
                </div>
                <div className="row mt-4">
                  <div className="col-8">
                    <label
                      htmlFor="CustomersAddress_Street"
                      className="required"
                    >
                      Endereço
                    </label>
                    <Form.Control
                      type="text"
                      name="Street"
                      className="form-control"
                      id="CustomersAddress_Street"
                      value={form_Data.CustomersAddress_Street}
                      onChange={chngFn}
                      required={true}
                      isInvalid={
                          form_Data.CustomersAddress_Street === '' &&
                          validated
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                      O Endereço é obrigatório!
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-4">
                    <label htmlFor="CustomersAddress_NumberFloor">
                      Porta/Fração
                    </label>
                    <Form.Control
                      type="text"
                      name="NumberFloor"
                      className="form-control"
                      id="CustomersAddress_NumberFloor"
                      value={form_Data.CustomersAddress_NumberFloor}
                      onChange={chngFn}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2">
                    <label
                      htmlFor="CustomersAddress_ZipCode"
                      className="required"
                    >
                      Código Postal
                    </label>
                    <Form.Control
                      type="text"
                      name="ZipCode"
                      className="form-control"
                      id="CustomersAddress_ZipCode"
                      value={form_Data.CustomersAddress_ZipCode}
                      onChange={chngFn}
                      required={true}
                      isInvalid={
                          form_Data.CustomersAddress_ZipCode === '' &&
                          validated
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                      O Código Postal é obrigatório!
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-5">
                    <label
                      htmlFor="CustomersAddress_Local"
                      className="required"
                    >
                      Localidade
                    </label>
                    <Form.Control
                      type="text"
                      name="Local"
                      className="form-control"
                      id="CustomersAddress_Local"
                      value={form_Data.CustomersAddress_Local}
                      onChange={chngFn}
                      required={true}
                      isInvalid={
                          form_Data.CustomersAddress_Local === '' &&
                          validated
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                      A Localidade é obrigatória!
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-5">
                    <label htmlFor="CustomersAddress_City" className="required">
                      Concelho
                    </label>
                    <Form.Control
                      type="text"
                      name="City"
                      className="form-control"
                      id="CustomersAddress_City"
                      value={form_Data.CustomersAddress_City}
                      onChange={chngFn}
                      required={true}
                      isInvalid={
                          form_Data.CustomersAddress_City === '' &&
                          validated
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                      O Concelho é obrigatório!
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary mr-auto"
              type="submit"
              form="addressForm"
            >
              Adicionar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
}


export default AddressCreate