
const BlankState = (props) => {
    
    if(props.loading) {
        return (
            <div className="p-3">
                <div className="list-updating"></div>
            </div>
        );
    } else if(props.type==='noresults') {
        return (
            <div className="p-3">
                {  props.onlyText
                    ? ''
                    : <img data-image src="/dist/img/blankstate/BlankSlate_NoResultsFound.svg" alt="no-item" width="533"  />
                }
                <div><h6>Com este critério de pesquisa, não existem resultados para apresentar.</h6></div>
            </div>
        );
    } else if(props.type==='norows') {
        return (
            <div className="p-3">
                {  props.onlyText
                    ? ''
                    : <img data-image src="/dist/img/blankstate/BlankSlate_NothingToShow.svg" alt="no-item" width="350"  />
                }
                <div className="pt-3"><h6>Não existe informação para apresentar.</h6></div>
            </div>
        );
    }

}

export default BlankState;