import { React, useState, useEffect} from 'react';
import MuiDataGrid from '../DataGrid';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import getLocalData from "../../functions/getLocalData";
import moment from 'moment';

const ElevatorList = (props) => {
  const [data, setData] = useState([]);

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState(false);
  /**/

  async function getElevatorList(customerId) {

    const CustomerId = customerId?customerId:0;

    setLoading(true);
    try {
      const fetchData = await fetchAPI(
        "/api.php/elevator/list?customerId="+CustomerId+"&tenantId="+getLocalData("user", "tenant"),
        "GET"
      );
      const res = await fetchData;
      setData(res);
      setLoading(false);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getElevatorList(props.customerId);
  }, [props.customerId]);

  const columns = [
    {
      field: "Elevators_Number",
      headerName: "Número",
      flex: 1,
    },
    {
      field: "Customers_Name",
      headerName: "Cliente",
      flex: 1,
      renderCell: (params) => (
        params.row.Customers_Reference + ' - ' + params.row.Customers_Name
      ),
    },
    {
      field: "CustomersAddress_Street",
      headerName: "Endereço",
      flex: 1,
    },
    {
      field: "Elevators_NextInspection",
      headerName: "Próxima Inspecção",
      flex: 1,
      valueFormatter: (params) => 
        params?moment(params).format("DD/MM/YYYY"):''
    },
    {   
      field: "InstallationTypes_Name", 
      headerName: "Tipo de Instalação", 
      flex: 1,
    },
    {
      field: "Manufactuers_Name",
      headerName: "Fabricante",
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => console.log(params.row.Elevators_Id)}
          >
            <i className="fas fa-folder pr-2"></i>
            Consultar
          </button>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={props.pageSize?props.pageSize:10}
        loading={loading}
        rowId={(params) => params.Elevators_Id}
        blankStateOnlyText={props.blankStateOnlyText}
        sortAsc={true}
        sortField="Customers_Name"
        columnVisibility={{
          ...props.columnVisibility
        }}
      />
    </>
  );
}

export default ElevatorList
