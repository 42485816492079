import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import DocumentTitle from '../../../functions/setDocumentTitle';
import BudgetProposalList from '../../../components/budgetproposal/list_budgetProposal';

const BudgetProposalsList = () => {
 
  return (
    <>
      {DocumentTitle("Orçamentos")}
      <Header />
      <SideNav activeItem={5} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Orçamentos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Orçamentos</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-body p-0">
              <BudgetProposalList 
                pageSize={50}
              />
            </div>
            {/* /.card-body */}

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </>
  );
}

export default BudgetProposalsList
