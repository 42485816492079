
import { React, useState, useEffect} from 'react';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import PopUpLoading from '../PopUpLoading';
import { Modal, Form } from "react-bootstrap";
import DropdownAPIList from '../DropdownAPIListValidation';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AvatarUser from '../common/avatarUser';
import moment from 'moment';
import Swal from 'sweetalert2';
import validateContact from '../../functions/validateCustomerContact';


const ContactDetail = (props) => {

    const [loading, setLoading] = useState({
        isLoading: false,
        message: "",
    });

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        alert: "",
    });

    const [modalShow, setModalShow] = useState(false);

    const [form_Data, setFormData] = useState({
        Customers_Id: '',
        CustomersContacts_Id: '',
        CustomersContacts_Value: '',
        CustomersContacts_IsMain: '',
        CustomersContacts_CreatedAt: '',
        CustomersContacts_UpdatedAt: '',
        Users_CreatedBy_FirstName: '',
        Users_CreatedBy_LastName: '',
        UsersAvatar_CreatedBy_Base64: '',
        UsersAvatar_CreatedBy_MimeType: '',
        Users_UpdatedBy_FirstName: '',
        Users_UpdatedBy_LastName: '',
        UsersAvatar_UpdatedBy_Base64: '',
        UsersAvatar_UpdatedBy_MimeType: '',
        ContactTypes: {
          Id: '',
          Name: '',
          Code: '',
        },
    });

    async function getContactDetail(id) {
      setLoading({
          isLoading: true,
          message: "A obter dados ...",
      });
    try {
      const fetchData = await fetchAPI(
        "/api.php/contact/detail?id=" + id,
        "GET"
      );
      const res = await fetchData;
      setFormData(res);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }

      setLoading({
          isLoading: false,
          message: "",
      });
    }

    async function updateContact() {
      setLoading({
        isLoading: true,
        message: "A atualizar o contacto ..."
      });
      setAlert({ open: false, type: alert.type, message: alert.message });
      try {
        const fetchData = await fetchAPI(
          "/api.php/contact/update",
          "POST",
          {
            CustomersContact: {
              Id: form_Data.CustomersContacts_Id,
              CustomerId: form_Data.Customers_Id,
              ContactTypeId: form_Data.ContactTypes.Id,
              Value: form_Data.CustomersContacts_Value,
              IsMain: form_Data.CustomersContacts_IsMain
            }
          }
        );
        const res = await fetchData;
          setAlert({
            open: true,
            message: res.message,
            type: res.status,
          });
          if(res.status!=='error') {
            getContactDetail(res.Id);
            props.onSave();
          } else {
            setLoading({
              isLoading: false
            });
          }
      } catch (err) {
          setAlert({
            open: true,
            message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
            type: "error",
          });
          setLoading({
            isLoading: false
          });
      }
      set_Validated(false);
    }

    async function getCustomerContactByType() {
      setLoading({
        isLoading: true
      });
      setAlert({ open: false, type: alert.type, message: alert.message });
      try {
        const fetchData = await fetchAPI(
          "/api.php/contact/listByType?customerId="+props.customerId+"&contactTypeId="+form_Data.ContactTypes.Id+"&isMain=1",
          "GET"
        );
        const res = await fetchData;
          if(res.length>0 && res[0].CustomersContacts_Id!==parseInt(form_Data.CustomersContacts_Id)) {
            Swal.fire({
              title: "Preferência do contacto",
              text: "O contacto "+res[0].CustomersContacts_Value+" já está definido como Principal. Se escolher este como principal o atual passará a Secundário. Pretende continuar? ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Sim, continuar"
            }).then((result) => {
              if (result.isConfirmed) {
                updateContact();
              }
            });
            setLoading({
              isLoading: false
            });
          } else {
            updateContact();
          }
      } catch (err) {
          setAlert({
            open: true,
            message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
            type: "error",
          });
          setLoading({
            isLoading: false
          });
      }
      set_Validated(false);
    }

    useEffect(() => {
      if(props.show) {
          setModalShow(true);
          getContactDetail(props.id);
      }
    }, [props]);

    const [validated, set_Validated] = useState(false);
    const [contactValidation, setContactValidation] = useState({
        isInvalid: false,
        message: ''
    });

    const submitFn = (event) => {
        
        const form = event.currentTarget;

        set_Validated(true);

        if (form.checkValidity() === false || contactValidation.isInvalid) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          event.preventDefault();
          if(form_Data.CustomersContacts_IsMain==='1') {
            getCustomerContactByType();
          } else {
            updateContact();
          }
        }
    };

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        if(name==='CustomersContacts_Value' && form_Data.ContactTypes.Id) {
          setContactValidation(validateContact(value,form_Data.ContactTypes.Code));
        }

        if(name==='ContactTypes' && form_Data.CustomersContacts_Value) {
          setContactValidation(validateContact(form_Data.CustomersContacts_Value,value.Code));
        }

        setFormData({
          ...form_Data,
          [name]: value,
        });

    };

    
    return (
      <>
        <SnackbarAlert
          open={alert.open}
          message={alert.message}
          type={alert.type}
        />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => {
            props.onHide();
            setModalShow(false);
          }}
          backdrop="static"
        >
          <PopUpLoading open={loading.isLoading} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Detalhes do Contacto
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid pb-4">
              <Form
                noValidate
                method="post"
                onSubmit={submitFn}
                id="contactForm"
              >
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="ContactTypes_Id" className="required">
                      Tipo de Contacto
                    </label>
                    <DropdownAPIList
                      id="ContactTypes_Id"
                      name="ContactTypes"
                      endpoint="/api.php/contact/listTypes"
                      invalidMessage="O Tipo de Contacto é obrigatório!"
                      defaultValue={form_Data.ContactTypes}
                      required={true}
                      isInvalid={
                          !form_Data.ContactTypes.Id &&
                          validated
                        }
                      label="Escolher Tipo"
                      onChange={(value) =>
                        handleOnChange({
                          target: { name: "ContactTypes", value: value },
                        })
                      }
                    />
                  </div>
                  <div className="col-6"></div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <label>
                      Preferência
                    </label>
                    <RadioGroup
                      row
                      name="CustomersContacts_IsMain"
                      value={form_Data.CustomersContacts_IsMain}
                      onChange={handleOnChange}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="Principal" />
                      <FormControlLabel value="0" control={<Radio />} label="Secundário" />
                    </RadioGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="Value">
                      Contacto
                    </label>
                    <Form.Control
                      type="text"
                      name="CustomersContacts_Value"
                      className="form-control"
                      id="Value"
                      value={form_Data.CustomersContacts_Value}
                      required={true}
                      onChange={handleOnChange}
                      isInvalid={
                          (!form_Data.CustomersContacts_Value || contactValidation.isInvalid) &&
                          validated
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                      {!form_Data.CustomersContacts_Value?'O Contacto é obrigatório!':contactValidation.message}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="row mt-5">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <label>Meta Dados</label>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <div className="col-6">
                          <label>Criado por</label>
                          <div className="col-12">
                            <AvatarUser
                              Name={
                                form_Data.Users_CreatedBy_FirstName +
                                " " +
                                form_Data.Users_CreatedBy_LastName
                              }
                              Base64={form_Data.UsersAvatar_CreatedBy_Base64}
                              MimeType={
                                form_Data.UsersAvatar_CreatedBy_MimeType
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <label>Atualizado por</label>
                          <div className="col-12">
                            <AvatarUser
                              Name={
                                form_Data.Users_UpdatedBy_FirstName +
                                " " +
                                form_Data.Users_UpdatedBy_LastName
                              }
                              Base64={form_Data.UsersAvatar_UpdatedBy_Base64}
                              MimeType={
                                form_Data.UsersAvatar_UpdatedBy_MimeType
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-6">
                          <label>Criado em</label>
                          <div className="col-12">
                            {moment(
                              form_Data.CustomersContacts_CreatedAt
                            ).format("DD/MM/YYYY HH:mm")}
                          </div>
                        </div>
                        <div className="col-6">
                          <label>Atualizado em</label>
                          <div className="col-12">
                            {moment(
                              form_Data.CustomersContacts_UpdatedAt
                            ).format("DD/MM/YYYY HH:mm")}
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary mr-auto"
              type="submit"
              form="contactForm"
            >
              Gravar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
}


export default ContactDetail