import {encode as base64_encode} from 'base-64';
import getLocalData from "./getLocalData";

export const fetchAPIEnctype = (endpoint,body,header) => {

    body.append('Log', JSON.stringify({
        UsersId: getLocalData("user", "id"),
        TenantId: getLocalData("tenant", "id"),
        IsPortal: 1
    }));

    const headers = {
      ...header,
      "Authorization": "Basic " + base64_encode("gestelevapiuser:Luskas#1981")
    }

    return fetch('https://api.gestelev.xyz'+endpoint, {
        method: "POST",
        headers: headers,
        body: body
    })
      .then((res) => res.json()).then((responseData) => {
        return responseData;
      })
  
  }