import { useEffect } from 'react'

function DocumentTitle(title) {
  
  useEffect(() => {
    document.title = 'GestElev - ' + title;
  }, [title]);
}

export default DocumentTitle
