import moment from 'moment';
import Chip from '@mui/material/Chip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorIcon from '@mui/icons-material/Error';

const ProposalExpiration = (props) => {

    if(!props.SentDate) {

        return 'Por enviar';

    } else {

        const sentDate = new Date(props.SentDate);
        const expirationDate = moment(sentDate.setDate(sentDate.getDate() + props.ExpirationDays));
        const currDate = new Date();

        if(props.StatusCode==='SENT') {

            if(moment(currDate).isAfter(expirationDate)) {
                
                return (
                    <Chip icon={<ErrorIcon />} label={expirationDate.format("DD/MM/YYYY")}  color="error" variant="outlined" />
                );

            } else {

                const configAlertDate = moment(currDate.setDate(currDate.getDate() + props.ConfigExpirationDays));
                
                if(expirationDate.isBefore(configAlertDate)) {

                    return (
                        <Chip icon={<ErrorOutlineIcon />} label={expirationDate.format("DD/MM/YYYY")}  color="warning" variant="outlined" />
                    );

                } else {

                    return expirationDate.format("DD/MM/YYYY");

                }

            }

        } else {
            return expirationDate.format("DD/MM/YYYY");
         }

    }

}
    
export default ProposalExpiration