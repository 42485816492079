import React from 'react'

const Footer = () => {
  return (
    <div>
        <footer className="main-footer">
            <strong>Desenvolvimento e manutenção por <a href="https://pmewebapps.pt" 
            target="_blank" rel="noreferrer">PmeWebApps</a>.</strong>
            <div className="float-right d-none d-sm-inline-block">
                <b>Versão</b> 1.0.1
            </div>
        </footer>
    </div>
  )
}

export default Footer
